<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="head">
        <img class="backgroundPhoto" src="../assets/background.jpg" />

        <div class="textContent">
          <!-- <v-row> -->
          <!-- col-xl-3 col-xl-offset-9 col-md-5 col-sm-10 offset-md-6 offset-sm-1  -->
          <v-col class="header_bg" v-if="this.found !== true">
            <br />
            <h2
              class="headline font-weight-bold"
              style="font-size: 50px !important; line-height: 45px; text-shadow: rgb(0 0 0 / 80%) 2px 2px;"
            >
              Dinner Is On Us
            </h2>

            <v-row justify="center">
              <p class="promo_msg">
                Enter your Promo-code located on your mailer to locate your FREE
                Dinner Event. Seating is Extremely Limited.
              </p>
            </v-row>
            <v-row justify="center">
              <v-form v-model="valid" class="col-sm-10">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="promo_code"
                        :rules="nameRules"
                        :counter="7"
                        :maxlength="7"
                        @keypress="isNumber($event)"
                        label="Promo Code"
                        class="bgstyle"
                        :validate-on-blur="true"
                        required
                        solo
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-btn
                        elevation="2"
                        class="locate_btn"
                        id="locate_btn"
                        dark
                        :loading="loading"
                        :disabled="loading"
                        @click="search_promo()"
                        >Locate Event</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-row>
          </v-col>
          <!-- </v-row> -->
          <v-spacer />
          <br />

          <v-col>
            <!-- <a name="form" id="form" class="form"> -->
            <main-form
              v-if="this.found == true"
              :seminar="this.seminar"
              :household="this.household"
            ></main-form>
            <!-- </a> -->
          </v-col>
        </div>

        <!-- </v-img> -->
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline primary">
          Promo Code not found.
        </v-card-title>

        <v-card-text>
          <br />
          We could not find that promo code. Please double check your entry and
          try again. If you believe it is correct, please contact
          <a href="mailto:support@annuitycheck.com">support@annuitycheck.com</a
          >.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Okay </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" width="500">
      <v-card>
        <v-card-title class="headline primary">
          You are already registered.
        </v-card-title>

        <v-card-text>
          <br />
          You have already been registered, if you need additional support,
          please contact:
          <a :href="'mailto:' + this.agentemail">{{ this.agentname }}</a> -
          {{ this.agentemail }}.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog2 = false"> Okay </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.bgstyle >>> .theme--light.v-counter {
  color: rgba(255, 255, 255, 0.7);
}

.container {
  padding-top: 0px;
}
.head {
  padding: 0px;
}

.thankYouDiv {
  padding: 20px;
  max-width: 600px;
  position: relative;
  margin: auto;
  background-color: rgba(0.3, 0.3, 0.3, 0.8);
  margin-top: 100px;
}

.backgroundPhoto {
  position: fixed;
  top: 0;
  height: 100%;
  /* filter: blur(5px);
  -webkit-filter: blur(5px); */
}

.textContent {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
}

.v-input input {
  background-color: white;
  color: black;
}

.promo_msg {
  text-align: center;
  color: white;
  margin-top: 30px;
  margin-left: 40px;
  margin-right: 40px;
  padding-left: 40px;
  padding-right: 40px;
  font-style: italic;
  font-size: 18px;
}

.headline {
  color: white;
  text-align: center;
}

.bgimg {
  background-position: top;
}
.header_bg {
  /* background-color: rgba(0.3, 0.3, 0.3, 0.8); */
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
  padding: 15px;
  max-width: 600px;
  position: relative;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 30px;
}

#locate_btn {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

#locate_btn:disabled {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

@media screen and (max-width: 768px) {
  .header_bg {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
  .thankYouDiv {
    margin-top: 0;
  }
}
</style>

<script>
import MARKETED_PERSON_BY_PROMO_CODE from "../graphql/MarketedPersonByPromoCode.gql";
import HOUSEHOLD_PERSON_BY_PROMO_CODE from "../graphql/HouseholdByPromoCode.gql";
import MainForm from "../components/MainForm";

export default {
  name: "Mailer",
  components: {
    MainForm,
  },

  methods: {
    isNumber(evt) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },

    search_promo: async function() {
      this.loading = true;
      console.log("Starting");
      if (this.valid) {
        console.log("VALID");

        let already_registered = await this.$apollo.query({
          query: HOUSEHOLD_PERSON_BY_PROMO_CODE,
          variables: { promoCode: +this.promo_code },
        });

        if (already_registered.data.householdByPromoCode) {
          console.log(already_registered);
          this.agentname =
            already_registered.data.householdByPromoCode.user.firstName +
            " " +
            already_registered.data.householdByPromoCode.user.lastName;
          this.agentemail =
            already_registered.data.householdByPromoCode.user.email;
          this.dialog2 = true;
          this.found = false;
          this.loading = false;
          return false;
        }

        let result = await this.$apollo.query({
          query: MARKETED_PERSON_BY_PROMO_CODE,
          variables: { promoCode: +this.promo_code },
        });
        console.log("got result");
        console.log(result);

        if (result.data.marketedPersonByPromoCode) {
          console.log("FOund something");

          this.household = result.data.marketedPersonByPromoCode;
          this.seminar = result.data.marketedPersonByPromoCode.campaign;
          this.found = true;
          this.loading = false;
        } else {
          // TODO: Use dialog boxes
          this.dialog = true;
          this.found = false;
          this.loading = false;
          return false;
        }
      } else {
        this.found = false;
        this.loading = false;
      }
    },
  },

  data: () => ({
    agentname: "",
    agentemail: "",
    dialog: false,
    dialog2: false,
    household: null,
    loading: false,
    seminar: null,
    promo_code: "",
    valid: false,
    found: false,
    nameRules: [
      (v) => !!v || "Promo Code is required",
      (v) => v.length == 7 || "Promo Code must be 7 Characters",
    ],
  }),
};
</script>
