<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: rgb(10, 10, 10); /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(50, 50, 50); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(50, 50, 50); /* creates padding around scroll thumb */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),
};
</script>
