<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="head">
        <img class="backgroundPhoto" src="../assets/background.jpg" />

        <div class="textContent">
          <v-col class="header_bg">
            <br />
            <h2
              class="headline font-weight-bold"
              style="font-size: 50px !important; line-height: 45px"
            >
              Thank You!
            </h2>

            <v-row justify="center">
              <p class="promo_msg">
                You have been successfully registered for this event.<br />
                <br />
                Feel free to close this page.
              </p>
            </v-row>
          </v-col>
          <!-- </v-row> -->
        </div>

        <!-- </v-img> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.container {
  padding-top: 0px;
}
.head {
  padding: 0px;
}

.thankYouDiv {
  padding: 20px;
  max-width: 600px;
  position: relative;
  margin: auto;
  background-color: rgba(0.3, 0.3, 0.3, 0.8);
  margin-top: 100px;
}

.backgroundPhoto {
  position: fixed;
  top: 0;
  height: 100%;
  /* filter: blur(5px);
  -webkit-filter: blur(5px); */
}

.textContent {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
}

.v-input input {
  background-color: white;
  color: black;
}

.promo_msg {
  text-align: center;
  color: white;
  margin-top: 30px;
  margin-left: 40px;
  margin-right: 40px;
  padding-left: 40px;
  padding-right: 40px;
  font-style: italic;
  font-size: 18px;
}

.headline {
  color: white;
  text-align: center;
}

.bgimg {
  background-position: top;
}
.header_bg {
  background-color: rgba(0.3, 0.3, 0.3, 0.8);
  border-radius: 5px;
  padding: 15px;
  max-width: 600px;
  position: relative;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 30px;
}

#locate_btn {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

#locate_btn:disabled {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

@media screen and (max-width: 768px) {
  .header_bg {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
  .thankYouDiv {
    margin-top: 0;
  }
}
</style>

<script>
export default {
  name: "ThankYou",
};
</script>
