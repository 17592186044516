<template>
  <v-layout justify-center>
    <v-card
      elevation="3"
      max-width="600"
      style="
        background-color: rgb(235, 236, 243) !important;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
      "
    >
      <v-form ref="form" v-model="valid">
        <h2 style="text-align: center">
          Register Below for your {{ this.seminar.venueName }} Dinner Event
        </h2>

        <v-row>
          <v-col>
            <h3>About Your Reservation</h3>

            <p>What night will you be attending?</p>
            <v-radio-group
              v-model="nightAttending"
              required
              :rules="[(v) => v !== null || 'Please Select a night']"
            >
              <v-radio
                v-for="n in available_nights.length"
                :disabled="available_nights[n - 1].disabled"
                :key="n"
                :label="available_nights[n - 1].value"
                :value="n - 1"
              ></v-radio>
            </v-radio-group>

            <h3 v-if="this.has_promo">
              Primary Registrant:
              {{ this.person.firstName }}
              {{ this.person.lastName }}
            </h3>

            <p v-if="!has_promo">Your Name</p>
            <v-text-field
              :rules="notBlankRules"
              v-if="!has_promo"
              label="First and last name"
              v-model="person.firstName"
              required
              solo
            ></v-text-field>
            <p v-if="!has_promo">Your Address</p>
            <vuetify-google-autocomplete
              v-if="!has_promo"
              id="map"
              append-icon="mdi-magnify"
              solo
              placeholder="Address"
              v-on:placechanged="getAddressData"
            >
            </vuetify-google-autocomplete>

            <p>Will your spouse or a guest be attending</p>
            <v-select
              solo
              :items="yes_no"
              :rules="yesnorules"
              v-model="spouseAttending"
            >
            </v-select>

            <v-text-field
              :rules="notBlankRules"
              v-if="spouseAttending === 'Yes'"
              label="First and last name of spouse or guest"
              v-model="spouseName"
              required
              solo
            ></v-text-field>

            <h3>Dinner Confirmation</h3>
            <v-text-field
              :rules="[
                (v) =>
                  !v ||
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  'E-mail must be valid',
              ]"
              label="Email"
              v-model="email"
              :validate-on-blur="true"
              :messages="[
                'We will send reservation details to this email address',
              ]"
              required
              solo
            ></v-text-field>
            <v-text-field-simplemask
              label="Mobile Phone"
              v-model="phone"
              v-bind:options="{
                inputMask: '(###) ### - ####',
                outputMask: '##########',
                empty: null,
                applyAfter: false,
                alphanumeric: false,
                lowerCase: false,
              }"
              v-bind:properties="{
                prefix: '',
                suffix: '',
                readonly: false,
                disabled: false,
                outlined: false,
                clearable: true,
                placeholder: '',
                solo: '',
                required: '',
                messages: [
                  'For Dinner confirmation only  (24-48 hours prior to event)',
                ],
                rules: [
                  (v) => !!v || 'Please Enter your phone number',
                  (v) =>
                    (v && v.length == 16) ||
                    'Phone number should be 10 digits long',
                ],
              }"
              v-bind:focus="focus"
              v-on:focus="focus = false"
            ></v-text-field-simplemask>

            <h3>Reservation Notes</h3>
            <v-textarea
              v-model="notes"
              solo
              name="input-7-4"
              label="Enter any special needs or requests"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-layout justify-center>
          <v-btn
            :disabled="loading"
            :loading="loading"
            dark
            elevation="2"
            class="locate_btn"
            id="locate_btn"
            @click="submit_form()"
            >Register</v-btn
          >
          <!-- Complete My Registration -->
        </v-layout>

        <br />
        <p style="font-weight: light; font-size: 12px">
          Disclaimer: Attendance is restricted to recipients over the age of 55
          that are named on the mailer and his/her spouse. The workshop may only
          be attended once during any 12-month period. Seating is Extremely
          Limited. As a courtesy, final confirmation calls will be made 24-48
          hours before the event. No agents or bankers will be admitted.
        </p>
      </v-form>
    </v-card>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline primary" style="color: white">
          Please fill out all required fields.
        </v-card-title>

        <v-card-text>
          <br />
          Please double check your entry and try again. If you believe it is
          correct, please contact
          <a href="mailto:support@annuitycheck.com">support@annuitycheck.com</a
          >.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Okay </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<style scoped>
.locate_btn {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

#locate_btn {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

#locate_btn:disabled {
  background-color: rgb(208, 2, 27) !important;
  color: white !important;
  padding: 30px !important;
  font-weight: bold;
  font-size: 20px !important;
  margin-top: -15px;
}

@media screen and (max-width: 768px) {
  .v-card {
    width: 100%;
  }
}
</style>

<script>
import CREATE_HOUSEHOLD from "../graphql/CreateHousehold.gql";
import CREATE_PEOPLE from "../graphql/CreatePeople.gql";
import SEND_TEMPLATE_EMAIL from "../graphql/SendTemplateEmail.gql";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
const axios = require("axios").default;

dayjs.extend(advancedFormat);

export default {
  name: "MainForm",
  props: ["seminar", "household"],

  mounted: function() {
    // TODO: FIX
    // this.seminar.events.edges[0].node.realCount = 4; // FAKE DATA
    if (this.household) {
      this.has_promo = true;
      this.person = this.household;
      this.leadtype = "mail";
    } else {
      this.leadtype = "weblink";
    }

    // SET AVAIBLE NIGHTS
    // this.available_nights
    console.log(this.household);
    this.seminar.events.edges.sort(function(a, b) {
      return new Date(a.node.startDate) < new Date(b.node.startDate) ? -1 : 1;
    });
    console.log(this.seminar.events.edges);

    this.available_nights = [];
    this.seminar.events.edges.forEach((element) => {
      let disabled = element.node.realCount >= element.node.capacity;
      let d = new Date(element.node.startDate)
      if (d < new Date()) {
        disabled = true;
      }
      let value = this.datefmt(element.node.startDate, element.node.startTime);
      if (disabled) {
        value += " (full)";
      }
      this.available_nights.push({
        value: value,
        disabled: disabled,
      });
    });

    if (
      this.available_nights.filter(function(i) {
        return !i.disabled;
      }).length === 0
    ) {
      this.overflow = true;
      this.available_nights.push({
        value: "Standby List; we will contact you regarding availablity",
        disabled: false,
      });
      this.nightAttending = this.available_nights.length - 1;
    }

    if (this.available_nights.length === 1) {
      this.nightAttending = 0;
    }
  },
  methods: {
    datefmt(date, time) {
      return dayjs(date).format("dddd, MMMM Do") + " at " + time;
    },
    // eslint-disable-next-line no-unused-vars
    getAddressData: function(addressData, placeResultData, id) {
      // this.address = addressData;
      console.log(addressData);
      this.person.street = addressData.name;
      this.person.city = addressData.locality;
      this.person.state = addressData.administrative_area_level_1;
      this.person.zip = addressData.postal_code;
    },

    async submit_form() {
      if (this.$refs.form.validate()) {
        // SEND FORM?

        this.loading = true;

        let result = await this.$apollo.mutate({
          mutation: CREATE_HOUSEHOLD,
          variables: {
            campaignId: this.seminar.dbId,
            city: this.person.city,
            email: this.email,
            eventId: this.overflow
              ? null
              : this.seminar.events.edges[this.nightAttending].node.dbId,
            leadType: this.leadtype,
            notes: this.notes,
            phone: this.phone,
            promoCode: this.person.promoCode,
            state: this.person.state,
            status: this.overflow ? "overflow" : "registered",
            street: this.person.street,
            userId: this.seminar.userId,
            zip: this.person.zip,
          },
        });
        console.log("HOUSEHOLD CREATION");
        console.log(result);

        // CREATE SPOUSE
        if (this.spouseAttending == "Yes") {
          this.$apollo.mutate({
            mutation: CREATE_PEOPLE,
            variables: {
              age: null,
              gender: null,
              householdId: result.data.createHousehold.id,
              name: this.spouseName,
              primary: false,
            },
          });
        }

        let final_result = await this.$apollo.mutate({
          mutation: CREATE_PEOPLE,
          variables: {
            age: this.person.age,
            gender: null,
            householdId: result.data.createHousehold.id,
            name: this.person.firstName + " " + this.person.lastName,
            primary: true,
          },
        });

        console.log(final_result);

        // CONFIRMATION EMAIL FOR CONSUMER
        this.$apollo.mutate({
          mutation: SEND_TEMPLATE_EMAIL,
          variables: {
            templateId: "consumer-seminar-registration",
            subject: "Your Seminar Registration has been Confirmed!",
            recipientEmail: this.email,
            recipientName: this.person.firstName + " " + this.person.lastName,
            data: JSON.stringify({
              venue_name: this.seminar.venueName,
              venue_location:
                this.seminar.street +
                ", " +
                this.seminar.city +
                ", " +
                this.seminar.state +
                ", " +
                this.seminar.zip,
              time: this.overflow
                ? "Overflow"
                : this.datefmt(
                    this.seminar.events.edges[this.nightAttending].node
                      .startDate,
                    this.seminar.events.edges[this.nightAttending].node
                      .startTime
                  ),
            }),
          },
        });

        if (!this.overflow) {
          axios
            .post("https://api-v3.annuitycheck.com/misc/sms", {
              to: this.phone,
              message: `Hi ${
                this.person.firstName
              }, thank you for registering for our workshop on ${this.datefmt(
                this.seminar.events.edges[this.nightAttending].node.startDate,
                this.seminar.events.edges[this.nightAttending].node.startTime
              )}.  DO NOT REPLY.`,
            })
            .then(function(response) {
              console.log(response);
            })
            .catch(function(error) {
              console.log(error);
            });
        }

        // CONFIRMATION EMAIL FOR AGENT
        this.$apollo.mutate({
          mutation: SEND_TEMPLATE_EMAIL,
          variables: {
            templateId: "agent-seminar-registration",
            subject:
              this.person.firstName +
              " " +
              this.person.lastName +
              " has just Registered for your Seminar!",
            recipientEmail: this.seminar.user.email,
            recipientName:
              this.seminar.user.firstName + " " + this.seminar.user.lastName,
            data: JSON.stringify({
              venue_name: this.seminar.venueName,
              name: this.person.firstName + " " + this.person.lastName,
              venue_location:
                this.person.street +
                ", " +
                this.person.city +
                ", " +
                this.person.state +
                ", " +
                this.person.zip,
              phone: this.phone,
              time: this.overflow
                ? "Overflow"
                : this.datefmt(
                    this.seminar.events.edges[this.nightAttending].node
                      .startDate,
                    this.seminar.events.edges[this.nightAttending].node
                      .startTime
                  ),
            }),
          },
        });

        this.$router.push("/thankyou");
      } else {
        // alert("Please fill out the form");
        this.dialog = true;
      }
    },
  },

  data: () => ({
    person: {
      firstName: null,
      lastName: "",
      age: null,
      promoCode: null,
      street: null,
      city: null,
      state: null,
      zip: null,
    },
    dialog: false,
    has_promo: false,
    primaryName: "",
    street: "",
    city: "",
    state: "",
    zipcode: "",
    leadtype: "",
    income: null,

    loading: false,
    valid: false,
    notBlankRules: [(v) => !!v || "Field is required"],
    yesnorules: [(v) => v == "Yes" || v == "No" || "Please Select Option"],
    yes_no: ["Please Select", "Yes", "No"],
    available_nights: [],
    nightAttending: null,
    overflow: false,
    spouseAttending: "Please Select",
    email: null,
    phone: null,
    reservationNotes: null,
    spouseName: null,
    notes: "",
  }),
};
</script>
