import Vue from "vue";
import VueRouter from "vue-router";
import Mailer from "../views/Mailer.vue";
import Seminar from "../views/Seminar.vue";
import ThankYou from "../views/ThankYou.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Mailer",
    component: Mailer,
  },
  {
    path: "/seminar/:id",
    name: "Seminar",
    component: Seminar,
  },
  {
    path: "/thankyou",
    name: "Thank You",
    component: ThankYou,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
